<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                    <ul id="pills-tab" role="tablist" class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationAdmin'}">Class Registration</router-link>
                        </li>
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationProgramSettingAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationProgramSettingAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationProgramSettingAdmin'}">Program Settings</router-link>
                        </li>
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationBatchSettingAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationBatchSettingAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationBatchSettingAdmin'}">Batch Settings</router-link>
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="form-group col-lg-6">
                        <label for="">Program Name</label>
                        <select v-model="programId" required class="form-control">
                            <option value="" selected disabled>-- Select Program Name --</option>
                            <option :value="item.id" v-for="(item, index) in programs" :key="index">
                                <span>
                                {{ item.name }}
                                </span>
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-lg-6">
                        <label for="">Last Registration Date</label>
                        <input type="date" v-model="lastRegistrationDate" class="form-control" placeholder="Insert your Date of Birth" required>
                    </div>
                    <div class="form-group col-6">
                        <label for="">Batch Name</label>
                        <input  type="text" class="form-control" placeholder="Insert batch name" v-model="batchName" required>
                    </div>
                    <div class="form-group col-lg-12 text-right mt-2">
                        <button @click="onSubmit()" class="btn btn-current" type="submit">Submit</button>
                    </div>
                </div>
                <div class="row">
                    <div v-if="isRetrieved && !isLoad" class="col-12 py-3">
                        <div class="table-responsive ml-0">
                            <datatable :class="'table'" :columns="columns" :data="programBatches" :page="1" :perPage="100">
                                <template name="default" slot-scope="{ row, index }">
                                    <tr v-if="row">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ row.name }}</td>
                                        <td class="text-center">
                                            <div v-if="row.status == 'ACTIVE'" class="bg-green text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block lh-34 text-center px-5">ACTIVE</div>
                                            <div v-else class="bg-grey fw-600 text-uppercase font-xssss rounded-lg d-inline-block px-2 lh-34 text-center px-5">INACTIVE</div>
                                        </td>
                                        <td class="text-center">
                                            <button v-if="row.status == 'ACTIVE'" @click="toggleButton(row.id, 'INACTIVE')" class="btn btn-outline-danger mr-2">DEACTIVATE</button>
                                            <button v-else @click="toggleButton(row.id, 'ACTIVE')" class="btn btn-outline-success mr-2">ACTIVATE</button>
                                            <button @click="deleteButton(row.id)" class="btn btn-outline-danger">DELETE</button>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="8">Nothing to see here</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>
                    </div>
                    <div v-if="isLoad" class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "Privillage",
    data(){
        return {
            programs: [],
            programName: "",
            programBatches: [],
            batchName: "",
            programId: "",
            lastRegistrationDate: "",
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Name', field: 'user.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Status', field: 'user.email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Action', field: 'user.username', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false}              
            ],
            isLoad: false,
            isRetrieved: false,
        }
    },
    created(){
        this.retrievePrograms()
    },
    watch:{
        amount: function() {
            this.amount = this.toIDR(this.amount)
        },
        programId: function() {
            this.retrieveProgramBatches()
        }
    },
    methods:{
        ymdToUnixTimestamp(dateString, timezoneOffset = 0) {
            // Create a Date object from the date string
            const date = new Date(dateString);
            // Set the time to 11:59:59 PM in the local timezone
            date.setHours(23, 59, 59, 999);

            // Adjust for the timezone offset (in hours)
            const offsetInMilliseconds = timezoneOffset * 60 * 60 * 1000;
            const timestamp = Math.floor((date.getTime() - offsetInMilliseconds) / 1000);

            return timestamp;
        },
        async onSubmit(){
            const payload = {
                program_id: this.programId,
                status: 'ACTIVE',
                batch_name: this.batchName,
                last_registration_date: this.ymdToUnixTimestamp(this.lastRegistrationDate)
            }
            console.log(payload)
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/batch`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                console.log('success', res.data)
                this.$swal({
                            toast: true,
                            title: 'Program',
                            text: 'Success Add Program Batch',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        })
                this.programName = ""
                this.amount = null
                this.retrieveProgramBatches()
            }).catch(err => {
                console.log('error', err.response)
            })
            this.batchName = "";
            this.lastRegistrationDate = "";
            this.programId = "";
        },
        retrievePrograms(){
            this.isLoad = true
            axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/program`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.programs = res.data.data
                this.isLoad = false
            }).catch(err => {
                console.log('error', err.response)
            })
        },
        retrieveProgramBatches(){
            if(this.programId){
                this.isLoad = true
                axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/batch?program_id=${this.programId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    // this.programs = res.data.data
                    this.programBatches = res.data.data
                    console.log('success', res.data.data)
                    this.isLoad = false
                }).catch(err => {
                    console.log('error', err.response)
                })
            }
            this.isRetrieved = true
        },
        toggleButton(id, status){
            const payload = {
                program_batch_id: id, 
                status: status
            }
            axios.patch(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/batch`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                console.log('success', res.data)
                this.$swal({
                            toast: true,
                            title: 'Program',
                            text: 'Success Update Batch',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        })
                this.retrieveProgramBatches()
            }).catch(err => {
                console.log('error', err.response)
            })
        },
        deleteButton(id){
            const payload = {
                batch_id: id
            }
            axios.patch(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/delete-batch`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                console.log('success', res.data)
                this.$swal({
                            toast: true,
                            title: 'Program',
                            text: 'Success Delete Batch',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        })
                this.retrieveProgramBatches()
            }).catch(err => {
                console.log('error', err.response)
            })
        }
    }

}
</script>
